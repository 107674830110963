import React, { useState } from "react"
import Layout from "../components/layout"
import { Process, Title, Toolset } from "../components/parts"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"

const Page = () => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = (event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <Layout title="Logo design">
      <Title heading="Logo design" />
      <div className="section-bottom">
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox} backdropClosesModal={true}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
                trackProps={{
                  swipe: "touch",
                  infinite: false,
                }}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
      <Process>
        <img src="/images/logo-design/process.png" alt="process" />
      </Process>
      <div className="section-bottom">
        <Toolset tools={["illustrator", "pencil"]} />
      </div>
    </Layout>
  )
}

export default Page

const photos = [
  {
    src: "/images/logo-design/1.jpg",
    width: 875,
    height: 290,
    title: "",
  },
  {
    src: "/images/logo-design/2.jpg",
    width: 670,
    height: 230,
    title: "",
  },
  {
    src: "/images/logo-design/3.jpg",
    width: 960,
    height: 462,
    title: "",
  },
  {
    src: "/images/logo-design/4.jpg",
    width: 900,
    height: 445,
    title: "",
  },
  {
    src: "/images/logo-design/5.jpg",
    width: 830,
    height: 410,
    title: "",
  },
  {
    src: "/images/logo-design/6.jpg",
    width: 960,
    height: 640,
    title: "",
  },
  {
    src: "/images/logo-design/7.jpg",
    width: 730,
    height: 340,
    title: "",
  },
  {
    src: "/images/logo-design/8.jpg",
    width: 610,
    height: 235,
    title: "",
  },
  {
    src: "/images/logo-design/9.jpg",
    width: 740,
    height: 520,
    title: "",
  },
]
